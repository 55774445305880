/**
 * File navigation.js.
 *
 * Handles toggling the navigation menu for small screens and enables TAB key
 * navigation support for dropdown menus.
 */

document.addEventListener('DOMContentLoaded', function () {

  let navTouch = document.getElementById('menu-burger');

  navTouch.addEventListener('click', function (event) {
    let menu = document.getElementById('menu-mobile');
    let burger = document.getElementById('menu-burger');

    if (burger.classList.contains('is-opened')) {
      burger.classList.remove('is-opened');
    }else{
      burger.classList.add('is-opened');
    }

    if (menu.classList.contains('is-opened')) {
      menu.classList.remove('is-opened');
    }else{
      menu.classList.add('is-opened');
    }
	});

	$('.menu-item-has-children > a').on( "click", function(event) {
		event.preventDefault();
		if ($(this).next('.sub-menu').hasClass('opened')) {
			$(this).next('.sub-menu').removeClass('opened');
		}else{
			$(this).next('.sub-menu').addClass('opened');
		}
	});

})