document.addEventListener('DOMContentLoaded', function () {

  $('#home-posts-carousel').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  $('#brand-carousel').slick({
    infinite: true,
    centerMode: true,
    centerPadding: '150px',
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          centerPadding: '100px',
        }
      },
      {
        breakpoint: 720,
        settings: {
          centerPadding: '80px',
        }
      }
    ]
  });

  $('#brand-carousel').slickLightbox()

  /**
  * Smooth scrolling to page anchor on click
  **/
  $("a[href*='#']").on("click", function() {
      if (
          location.hostname == this.hostname
          && this.pathname.replace(/^\//,"") == location.pathname.replace(/^\//,"")
      ) {
          var anchor = $(this.hash);
          anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) +"]");
          if ( anchor.length ) {
              $("html, body").animate( { scrollTop: anchor.offset().top - 56 }, 500);
          }
      }
  });

})